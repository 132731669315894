<template>
  <div class="wrapper">
    <div class="content">
        <div class="webcam">
          <div v-if="btn.camera && !webPhoto">
            <WebCamera @picture-taken="webPhoto = $event"/>
          </div>
          <div class="" v-if="webPhoto && btn.camera">
            <img :src="webPhoto" class="webcam-photo">
            <div class="webcam-buttons">
              <button @click="usePhoto" class="webcam__button">Use photo</button>
              <button @click="cancelPhoto" class="webcam__button">Cancel</button>
            </div>
          </div>
          <div class="webcam-confirmation">
            <p class="confirmation__photo">{{ confirmation }}</p>
          </div>
        </div>
        <div class="block-loader-photo" v-if="btn.loaderPhoto">
          <label for="user-photo" class="input-label">Upload your photo</label>
          <div class="loader-photo file-input">
            <input @change="onUploadUserPhoto" ref="userPhoto" type="file" id="user-photo">
          </div>
        </div>
        <div v-if="btn.eignvalue">
          <label for="eignvalue" class="input-label">Eignvalue</label>
          <input type="text" id="eignvalue" v-model="eigntext" class="eignvalue-input" placeholder="Enter text"
          >
        </div>
        <div class="block-choice">
          <button @click="useEignvalue" class="choice__button">
            Eignvalue
          </button>
          <button @click="useCamera" class="choice__button">
            WebCamera
          </button>
          <button @click="useLoader" class="choice__button">
            Load photo
          </button>
        </div>
        <label for="file-document" class="input-label">
          Document photo to upload</label>
        <div class="block-document">
          <input type="radio" @click="clickRadio" v-model="typeDocument" id="card-id" value="cardId" name="document" class="document-radio">
          <label for="card-id">South african card id</label>
          <br>
          <input type="radio" @click="clickRadio" v-model="typeDocument" id="license" value="license" name="document" class="document-radio">
          <label for="license">South african drivers licence</label>
          <p class="document-error">{{ documentError }}</p>
          <div class="document-file file-input">
            <input @change="onUploadDocument" ref="fileDocument" type="file" id="file-document">
          </div>
        </div>

    </div>
    <br>
    <div class="block-submit">
      <p class="submit__error" v-if="photoError">{{ photoError }}</p>
      <button @click="submitForm" class="submit__button" :disabled="disabled.submit" :style="disabled.submit === true ? 'background: #808080; ' : ''">
        <span v-if="!show.throbber">Submit Query</span>
        <i v-if="show.throbber" class="loader"></i>
      </button>
    </div>
    <div class="block-response">
      <div class="response-success" v-if="status === 'success'">
        <p class="success__title">Success</p>
        <p class="success__result">Сoincidence: {{ dataFromBackend }}</p>
      </div>
      <div class="response-fail" v-else-if="status === 'fail'">
        <p class="fail__result">{{ dataFromBackend }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import WebCamera from "../components/WebCamera";
import axios from "axios";

export default {
  name: 'Start',
  components: {
    WebCamera
  },
  data() {
    return {
      submitQuery: false,
      fileDocument: null,
      typeDocument: null,
      documentError: "",
      userPhoto: null,
      webPhoto: null,
      eigntext: null,
      dataFromBackend: "",
      photoError: "",
      btn: {
        eignvalue: true,
        loaderPhoto: false,
        camera: false,
      },
      show: {
        throbber: false
      },
      disabled: {
        submit: false
      },
      webCam: false,
      confirmation: "",
      status: ""
    }
  },
  methods: {
    async submitForm() {
      this.status = "";

      this.submitQuery = true;

      if (!this.fileDocument) {
        this.photoError = "Upload photo of document please";
        return;
      } else if (this.btn.camera && this.webPhoto && !this.confirmation){
        this.photoError = "Confirm web photo please";
        return;
      } else if (!this.webPhoto && !this.userPhoto && !this.eigntext) {
        this.photoError = "Upload photo please";
        return;
      }

      if(!this.typeDocument){
        this.documentError = "Please choose type of document";
        return;
      }

      let formData = new FormData();

      if(this.userPhoto) {
        formData.append("photoFile", this.userPhoto);
      } else if (this.webPhoto) {
        formData.append("webPhoto", this.webPhoto);
        console.log("WEB",this.webPhoto)
      } else {
        formData.append("eigntext", this.eigntext);
      }

      formData.append("typeDocument", this.typeDocument);
      formData.append("documentFile", this.fileDocument);

      try {
        this.show.throbber = true;
        this.disabled.submit = true;
        const response = await axios.post(process.env.VUE_APP_API_URL+'/compare', formData);
        console.log("Response from backend", response);

        if (response.data.success === true && response.data.coincidence) {
          this.status = "success";
          this.dataFromBackend = response.data.coincidence;
        } else if (response.data.success === false && response.data.coincidence){
          this.status = "fail";
          this.dataFromBackend = "Faces do not match. Try again please";
        } else if (response.data.foundFace === false){
          this.status = "fail";
          this.dataFromBackend = "We could not find the face in the card id/the green id book. Try again please";
        } else {
          this.status = "fail";
          this.dataFromBackend = "Service does not answer. Please try later";
        }
        this.show.throbber = false;
        this.disabled.submit = false;

      } catch (e) {
        this.status = "fail";
        this.dataFromBackend = "Service does not answer. Please try later";
        this.show.throbber = false;
        this.disabled.submit = false;
        console.log("Submit form ERROR::", e);
      }

      this.clear();
      return;
    },
    clear() {
      if(this.userPhoto){
        this.$refs.userPhoto.value = null;
      }
      if(this.fileDocument){
        this.$refs.fileDocument.value = null;
      }
      this.userPhoto = null;
      this.fileDocument = null;
      this.webPhoto = null;
      this.eigntext = null;
      this.confirmation = "";
      this.typeDocument = null;
      this.photoError = "";
      this.documentError = "";
      this.btn.camera = false;
      this.btn.loaderPhoto = false;
      this.btn.eignvalue = true;
    },
    usePhoto(){
      this.confirmation = "Photo is added successfully";
      this.photoError = "";
    },
    cancelPhoto(){
      this.webPhoto = null;
      this.confirmation = "";
    },
    useEignvalue(){
      this.btn.eignvalue = true;
      this.btn.loaderPhoto = false;
      this.btn.camera = false;
      this.userPhoto = null;
      this.webPhoto = null;
      this.confirmation = "";
      this.photoError = "";
      this.status = "";
      this.dataFromBackend = "";
    },
    useCamera(){
      this.btn.camera = true;
      this.btn.eignvalue = false;
      this.btn.loaderPhoto = false;
      this.userPhoto = null;
      this.eigntext = null;
      this.photoError = "";
      this.status = "";
      this.dataFromBackend = "";
    },
    useLoader(){
      this.btn.loaderPhoto = true;
      this.btn.camera = false;
      this.btn.eignvalue = false;
      this.webPhoto = null;
      this.eigntext = null;
      this,this.confirmation = "";
      this.photoError = "";
      this.status = "";
      this.dataFromBackend = "";
    },
    onUploadUserPhoto(e) {
      e.preventDefault();
      this.userPhoto = e.target.files[0];
      console.log("TEST DATA userPhoto", this.userPhoto);
      this.photoError = "";
    },
    clickRadio(){
      this.documentError = "";
    },
    onUploadDocument(e) {
      e.preventDefault();
      this.fileDocument = e.target.files[0];
      console.log("TEST DATA fileDocument", this.fileDocument);
      this.photoError = "";
    }
  }
}
</script>

<style lang="scss" scoped>
//@import '../index.css';
  body{
    font-family: Verdana, sans-serif;
    font-size: 14px;
  }
  .wrapper{
    width: 24rem;
    margin: 0 auto;
    padding: 15px;
  }
  .webcam {
    width: 100%;
  }
  .webcam-buttons{
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .webcam__button{
    display: block;
    width: 110px;
    height: 30px;
  }
  .webcam__button:hover{
    box-shadow: 0 6px 4px -4px rgba(0, 0, 0, .2);
    border-radius: 2px;
  }
  .webcam-confirmation{
    margin-top: 10px;
  }
  .confirmation__photo{
    color: rgb(39, 201, 39);
    font-weight: 500;
    text-align: center;
  }
  .input-label{
    display: block;
    margin: 10px 0;
  }
  .file-input{
    border: 1px solid rgb(169, 168, 168);
    border-radius: 5px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .eignvalue-input{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    background-color: rgb(251, 251, 251);
    font-size: 0.8rem;
  }
  .block-document{
    margin: 10px 0;
  }
  .document-radio{
    margin: 0 5px 5px 0;
    transform: scale(0.7);
  }
  .document-error{
    text-align: center;
    margin: 10px 0;
    color: rgb(239, 102, 17);
    font-weight: 500;
  }
  .block-choice{
    margin: 15px 0;
    display: flex;
    justify-content: center;
  }
  .choice__button{
    background-color: rgb(0, 162, 255);
    color: white;
    width: 33%;
    padding: 5px 0;
    line-height: 2;
    margin: 5px;
    border-radius: 4px;
  }
  .block-submit{
    width: 100%;
  }
  .submit__error{
    color: rgb(239, 102, 17);
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
  }
  .submit__button{
    display: block;
    background-color: rgb(0, 162, 255);
    color: white;
    width: 100%;
    padding: 5px 0;
    line-height: 2;
    margin: 0 auto;
    border-radius: 4px;
  }
  .block-response{
    width: 100%;
    margin: 20px 0;
  }
  .response-success{
    width: 100%;
    margin: 0 auto;
  }
  .success__title{
    text-align: center;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
    color: rgb(57, 208, 7);
  }
  .success__result{
    text-align: center;
    padding: 15px 0;
    font-weight: 600;
    color: rgb(57, 208, 7);
  }
  .response-fail{
    width: 100%;
    margin: 0 auto;
  }
  .fail__result{
    color: rgb(239, 102, 17);
    font-weight: 500;
    text-align: center;
    margin: 10px 0;
  }
  .loader-container {
    position: absolute;
  }
  .loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
  }

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader::after, .loader::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}
</style>
