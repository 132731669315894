<template>
  <div>
    <video ref="video" @canplay="initCanvas()"></video>
    <div class="block-button">
      <button @click="takePhoto()" class="button">Take photo</button>
    </div>
    <canvas ref="canvas" style="display:none"></canvas>
  </div>
</template>

<script>
  export default {
    name: "WebCamera",
    mounted(){
      this.canvas = this.$refs.canvas,
      this.video = this.$refs.video,
      this.startCapture()
    },
    methods: {
      startCapture(){
        navigator.mediaDevices.getUserMedia({video: true, audio: false}).then(stream => {
          this.video.srcObject = stream
          this.video.play()
        }).catch(e => {
          console.log("startCapture Error", e);
        })
      },
      initCanvas(){
        this.canvas.setAttribute("width", this.video.videoWidth)
        this.canvas.setAttribute("height", this.video.videoHeight)
      },
      async takePhoto(){
        let ctx = this.canvas.getContext("2d");
        ctx.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight)
        this.$emit("picture-taken", this.canvas.toDataURL("image/jpeg"))
      }
    },
    data(){
      return {
        video: null,
        canvas: null
      }
    }
  }
</script>

<style scoped>
.block-button{
  margin-top: 15px;
}
.button{
    display: block;
    margin: 0 auto;
    width: 110px;
    height: 30px;
    border: 1px solid #00a2ff;
    border-radius: 4px;
  }
.button:hover{
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, .2);
  border-radius: 2px;
}
</style>
