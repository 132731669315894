<template>
  <i v-if="show.mainThrobber" class="loader" style="    display: block;
    margin: 0 auto;
    margin-top: 30px;"></i>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
      <th scope="col" class="px-10 py-3">
        User Photo
      </th>
      <th scope="col" class="px-6 py-3">
        Document Photo
      </th>
      <th scope="col" class="px-6 py-3">
        User IP
      </th>
      <th scope="col" class="px-6 py-3">
        Date
      </th>
      <th scope="col" class="px-6 py-3">
        Percent
      </th>
      <th scope="col" class="px-6 py-3">
        Actions
      </th>
    </tr>
    </thead>
    <tbody>
    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="item in items" :key="item.id">
      <td v-if="item.firstPhoto">
        <img :src="item.firstPhoto"  v-if="item.firstPhoto.includes('data')" style="width: 128px; height: 128px;"/>
        <img v-else src="../assets/black-window.png"  style="width: 128px; height: 128px; " class="cursor-pointer"/>
      </td>
      <td v-if="item.secondPhoto">
        <img :src="item.secondPhoto"  v-if="item.secondPhoto.includes('data')" style="width: 128px; height: 128px;"/>
        <img v-else src="../assets/black-window.png"  style="width: 128px; height: 128px;" class="cursor-pointer"/>
      </td>
      <td v-if="item.IP" style="text-align: right; padding-right: 28px;">
        <span>{{item.IP}}</span>
      </td>
      <td v-if="item.Date">
        <span>{{item.Date}}</span>
      </td>
      <td v-if="item.Percent" style="text-align: right; padding-right: 28px;">
        <span class="font-bold">{{item.Percent}}</span>
      </td>
      <td>
        <button  style="width: 70px;" class="choice__button cursor-pointer " v-if="!item.firstPhoto.includes('data') && !show.throbber[item.id]"  @click="loadingPhoto(item.id, [item.firstPhoto, item.secondPhoto])">Loading</button>
        <i v-if="show.throbber[item.id]" class="loader"></i>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="content-center pagination">
    <button class="choice__button cursor-pointer"  @click="offset-=7, getHistory(offset)" v-if="offset > 0">PREVIOUS</button>
    <button class="choice__button cursor-pointer"  @click="offset+=7, getHistory(offset)" v-if="this.items.length">NEXT</button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminTable",
  data()  {
    return {
      items: [],
      hasImage: false,
      offset: 0,
      nextButton: true,
      show: {
        mainThrobber: true,
        throbber: []
      }
    }
  },
  methods: {
   async loadingPhoto(id, photos) {
     this.show.throbber[id] = true;
     let tempBody = {
       photoFirst: photos[0],
       photoSecond: photos[1]
     }
     const response = await axios.post(process.env.VUE_APP_API_URL+'/getPhoto', tempBody);

     if(response) {
       this.items.forEach(elem => {
         if (elem.id === id) {
           elem.firstPhoto = response.data[0].firstPhoto;
           elem.secondPhoto = response.data[0].secondPhoto;
         }
       })
       this.show.throbber[id] = false;
     }
    },
    async getHistory(offset) {
      console.log("TEST DATA", offset)
      let tempBody = {
        rows: 6,
        offset: offset
      }
      const response = await axios.post(process.env.VUE_APP_API_URL+'/getHistory', tempBody);

      if (response.data) {
        this.items = response.data
      } else {
        this.nextButton = false;
      }
      this.show.mainThrobber = false;
    }
  },
  async created() {
    await this.getHistory(this.offset);
  }
}
</script>

<style scoped lang="scss">
.submit__button{
  display: block;
  background-color: rgb(0, 162, 255);
  color: white;
  width: 100%;
  padding: 5px 0;
  line-height: 2;
  margin: 0 auto;
  border-radius: 4px;
}
.choice__button {
  background-color: rgb(0, 162, 255);
  color: white;
  width: 33%;
  padding: 5px 0;
  line-height: 2;
  margin: 5px;
  border-radius: 4px;
}
.loader-container {
  position: absolute;
}
.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader::after, .loader::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}
@media screen and (max-width: 900px) {
  td {
    & img {
      width: 30px;
    }
  }
}
</style>
