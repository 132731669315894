<template>
  <div class="container mx-auto text-center" v-if="!auth">
    <div class="password">
      <div class="mb-6">
        <label for="large-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter password</label>
        <input type="password"  v-model="password" id="large-input" class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <button class="choice__button cursor-pointer" @click="authUser">AUTHORIZE</button>
      <br>
      <span class="wrong-password" v-if="error">
        Wrong password
      </span>
    </div>
  </div>
  <div class="container mx-auto text-center" v-if="auth">
      <span class="content__text mb-5 text-4xl">
        Results
      </span>
    <AdminTable />
  </div>
</template>

<script>
import AdminTable from "@/components/AdminTable";
export default {
  name: "AdminView",
  components: {
    AdminTable
  },
  data()  {
    return {
      password: '',
      auth: false,
      error: false
    }
  },
  methods: {
    authUser() {
      if (this.password === process.env.VUE_APP_AUTH_PASSWORD) {
        this.auth = true;
        this.error = false;
        this.password = '';
      } else {
        this.error = true;
      }
    }
  }
}
</script>

<style scoped>
body{
  font-family: Verdana, sans-serif;
  font-size: 14px;
}
.choice__button {
  background-color: rgb(0, 162, 255);
  color: white;
  width: 33%;
  padding: 5px 0;
  line-height: 2;
  margin: 5px;
  border-radius: 4px;
}
</style>
