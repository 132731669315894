<template>
  <RouterView></RouterView>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  -webkit-font-smoothing: antialiased;
  -mos-asx-font-smoothing: grayscale;
}
</style>
